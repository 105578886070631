import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import Image from 'next/image';

import { Button, GlobalContext, IconButton, TextFieldForm } from '../../common';
import { JOIN_US_FORM_VALIDATION_SCHEMA } from '../../../constants/forms';
import { JoinUsFormData } from '../../../types/forms';
import { submitForm } from '../../../api/methods/forms';
import { ERROR_MESSAGE_GENERIC } from '../../../constants/common';
import { RECAPTCHA_SITE_KEY } from '../../../helpers/envs';

const headingId = 'JoinUsModal-heading';

const JoinUsModal = () => {
  const { isModalOpen, closeModal } = useContext(GlobalContext);

  const [isSubmitSuccessful, setSubmitSuccessful] = useState<boolean>(false);

  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);

  const { register, handleSubmit, formState } = useForm<JoinUsFormData>({
    resolver: yupResolver(JOIN_US_FORM_VALIDATION_SCHEMA),
  });

  const handleDismiss = () => closeModal('join_us');

  const onSubmit = handleSubmit(
    async ({
      name,
      emailAddress,
      phoneNumber,
      restaurantName,
      restaurantAddress,
    }) => {
      await submitForm('join-us', {
        name,
        emailAddress,
        phoneNumber,
        restaurantName,
        restaurantAddress,
      })
        .then(() => {
          setSubmitSuccessful(true);
        })
        .catch(() => {
          toast.error(ERROR_MESSAGE_GENERIC);
        });
    }
  );

  const executeReCaptcha: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    // This seems to be dumb and indeed it is...
    // https://github.com/dozoisch/react-google-recaptcha/issues/129#issue-405863364
    setTimeout(() => reCaptchaRef.current?.reset(), 200);
    await reCaptchaRef.current?.executeAsync();
  };

  return (
    <DialogOverlay
      isOpen={isModalOpen('join_us')}
      onDismiss={handleDismiss}
      className="z-50 px-3 bg-overlay"
    >
      <DialogContent
        aria-labelledby={headingId}
        className="relative w-full p-0 z-50 box-content rounded-md bg-gray-light overflow-hidden sm:max-w-lg lg:max-w-screen-lg"
      >
        <IconButton
          a11yLabel="Close modal"
          icon="cross"
          iconAlt="cross icon"
          onClick={handleDismiss}
          className="absolute top-3 right-3 w-5 h-5 focus:ring-offset-gray-light"
          dataTestId="modal-close"
        />

        <div className="flex flex-col items-center lg:flex-row lg:items-stretch">
          <div className="hidden lg:block lg:relative lg:w-full">
            <Image
              src="/images/join-us.jpeg"
              alt="Fries with vegetables photo"
              className="object-center object-cover pointer-events-none"
              quality={50}
              layout="fill"
            />
          </div>

          <div className="w-full px-4 py-8 max-w-lg md:p-8 lg:p-12">
            <h2 className="mb-6 text-2xl font-semibold" id={headingId}>
              Join us
            </h2>

            {!isSubmitSuccessful ? (
              <form
                onSubmit={RECAPTCHA_SITE_KEY ? executeReCaptcha : onSubmit}
                data-testid="JoinUs-form"
              >
                <TextFieldForm
                  {...register('name')}
                  autoComplete="name"
                  className="w-full mb-5"
                  error={formState.errors.name}
                  placeholder="Full name"
                />

                <TextFieldForm
                  {...register('emailAddress')}
                  autoComplete="email"
                  className="w-full mb-5"
                  error={formState.errors.emailAddress}
                  name="emailAddress"
                  placeholder="Email address"
                  type="email"
                />

                <TextFieldForm
                  {...register('phoneNumber')}
                  autoComplete="tel"
                  className="w-full mb-5"
                  error={formState.errors.phoneNumber}
                  placeholder="Phone Number"
                />

                <TextFieldForm
                  {...register('restaurantName')}
                  className="w-full mb-5"
                  error={formState.errors.restaurantName}
                  placeholder="Restaurant Name"
                />

                <TextFieldForm
                  {...register('restaurantAddress')}
                  className="w-full mb-8"
                  error={formState.errors.restaurantAddress}
                  placeholder="Restaurant Address"
                />

                {!!RECAPTCHA_SITE_KEY && (
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    size="invisible"
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={async (token) => {
                      if (!token) {
                        // Token expired
                        return;
                      }

                      onSubmit();
                    }}
                  />
                )}

                <Button
                  className="w-full focus:ring-offset-gray-light"
                  disabled={formState.isSubmitting}
                  type="submit"
                >
                  {formState.isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
              </form>
            ) : (
              <div className="flex flex-col items-center">
                <p>
                  Thank you for choosing to join us! Our team will contact you
                  shortly.
                </p>

                <div className="my-28">
                  <Image
                    src="/images/illustration-chat-bubbles.svg"
                    alt="Chat bubbles illustration"
                    width={129}
                    height={80}
                    quality={100}
                  />
                </div>

                <Button
                  className="w-full focus:ring-offset-gray-light"
                  onClick={handleDismiss}
                >
                  Close
                </Button>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export default JoinUsModal;
