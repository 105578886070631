import {
  APIFormType,
  ContactUsFormData,
  JoinUsFormData,
} from '../../types/forms';
import { API, composeDefaultHeaders } from '../index';

/**
 * Send a request containing form data to the endpoint responsible for handling form requests
 * @param type Form type accepted by API
 * @param formData Form data to be sent in the request
 */
export const submitForm = (
  type: APIFormType,
  formData: ContactUsFormData | JoinUsFormData
) =>
  API.post(
    '/form-message',
    {
      type,
      ...formData,
    },
    {
      headers: composeDefaultHeaders(),
    }
  );
